// this component is used only for demo page on polotno.com
import { downloadFile } from 'polotno/utils/download';
import { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { Spinner } from '@blueprintjs/core';

const getParam = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };

const key = getParam('KEY');

const saveAsVideo = async ({ store, pixelRatio = 1, fps = 30, onProgress }) => {
  const json = store.toJSON();
  const req = await fetch(`https://api.polotno.dev/api/renders?KEY=${key}`, {
    method: 'POST',
    headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          design: json,
          pixelRatio,
          format: 'mp4',
        }),
      }
    );
    const job = await req.json();
    while (true) {
    const jobReq = await fetch(
      `https://api.polotno.dev/api/renders/${job.id}?KEY=${key}`
    );
    const jobData = await jobReq.json();
    if (jobData.status === 'done') {
        downloadFile(jobData.output, 'polotno.mp4');
        break;
      } else if (jobData.status === 'error') {
        throw new Error('Failed to render video');
      } else {
        onProgress(jobData.progress, jobData.status);
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  };

// it is important to define component onside of `MyToolbar` render function
export const DownloadVideoButton = ({ store }) => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');

  return (
    <Button
      intent="primary"
      fill
      disabled={isLoading}
      icon={isLoading ? <Spinner size={20}/> : undefined}
      // loading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        try {
          await saveAsVideo({
            store,
            onProgress: (progress, status) => {
              setProgress(progress);
              setStatus(status);
            },
          });
        } catch (error) {
          alert(error.message || 'Failed to download video');
        } finally {
          setIsLoading(false);
          setProgress(0);
          setStatus('');
        }
      }}
    >
      {isLoading ? `Rendering (${Math.round(progress)}%)` : 'Render video'}
    </Button>
  );
};
